<script lang="ts">
  import { ChevronLeftIcon, ChevronRightIcon } from "lucide-svelte";
  import { onMount, tick } from "svelte";

  export let items: any[];
  export let title: string | undefined = undefined;
  export let cardWidth: number;
  export let cardGap: number;

  let carousel: HTMLElement;
  let headingContainer: HTMLElement;

  let isFirstElementVisible = true;
  let isLastElementVisible = false;

  // Note: Dynamically calculate the right margin of the carousel based on the
  // marketing container's placement.
  let carouselRightMargin = 0;

  function checkCarouselControls() {
    if (carousel) {
      isFirstElementVisible = carousel.scrollLeft === 0;
      isLastElementVisible =
        carousel.scrollLeft === carousel.scrollWidth - carousel.clientWidth;
    }
  }

  function handleResize() {
    if (headingContainer) {
      carouselRightMargin = headingContainer.getBoundingClientRect().left;
      tick().then(checkCarouselControls);
    }
  }

  $: items && handleResize();
  onMount(handleResize);

  function scroll(direction: "left" | "right") {
    if (carousel) {
      carousel.scrollTo({
        left:
          direction === "left"
            ? carousel.scrollLeft - (cardWidth + cardGap)
            : carousel.scrollLeft + (cardWidth + cardGap),
        behavior: "smooth",
      });
    }
  }
</script>

<svelte:window on:resize={handleResize} />

{#if items.length > 0}
  <div class="marketing-container mb-10">
    <div class="flex gap-6 items-start" bind:this={headingContainer}>
      <div class="flex flex-col sm:flex-row gap-6 items-start">
        {#if title}
          <h2 class="marketing-h3 text-light-green">{title}</h2>
        {/if}
        <div class="md:mt-1">
          <slot name="cta" />
        </div>
      </div>

      {#if !isFirstElementVisible || !isLastElementVisible}
        <div class="ml-auto flex">
          <button
            class="group p-1 rounded hover:enabled:bg-light-green/10 disabled:opacity-50"
            on:click={() => scroll("left")}
            disabled={isFirstElementVisible}
            aria-label="Scroll left"
          >
            <ChevronLeftIcon
              size={32}
              class="stroke-[1.5] group-[&:not(:disabled)]:text-light-green"
            />
          </button>
          <button
            class="group p-1 rounded hover:enabled:bg-light-green/10 disabled:opacity-50"
            on:click={() => scroll("right")}
            disabled={isLastElementVisible}
            aria-label="Scroll right"
          >
            <ChevronRightIcon
              size={32}
              class="stroke-[1.5] group-[&:not(:disabled)]:text-light-green"
            />
          </button>
        </div>
      {/if}
    </div>
  </div>

  <div class="carousel" bind:this={carousel} on:scroll={checkCarouselControls}>
    <div class="marketing-container flex" style:gap="{cardGap}px">
      <slot />
      <div class="flex-shrink-0" style:width="{carouselRightMargin}px"></div>
    </div>
  </div>
{/if}

<style lang="postcss">
  /* For Webkit-based browsers (Chrome, Safari and Opera) */
  .carousel::-webkit-scrollbar {
    display: none;
  }

  /* For IE, Edge and Firefox */
  .carousel {
    @apply overflow-x-auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
</style>
